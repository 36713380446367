import React, { useState } from "react";
import Customer from "../components/Customer";
import AppSyncProvider from "../components/AppSyncProvider"

const ResultsPage = (props) => {
  // const { user, isAuthenticated, isLoading } = useAuth0();
  const customerID = props.customerID;
  const [retryStatus, setRetryStatus] = useState(false);
  /*

    Logic for sending over the CID

  */
  // Customer();

  function removePastResult() {
    var timeLogo = document.getElementById(props.timeLogo);
    timeLogo.remove();

    var timeLabel = document.getElementById(props.timeLabel);
    timeLabel.remove();

    var timeProfile = document.getElementById(props.timeProfile);
    timeProfile.remove();

    var timeLogoutButton = document.getElementById(props.timeLogoutButton);
    timeLogoutButton.remove();

    setRetryStatus(true);
  }

  if (retryStatus) {
    return (
      <div>
        <AppSyncProvider></AppSyncProvider>
      </div>
    );
  } else {
    return (
      <div>
        <Customer customerID={customerID}></Customer>
        <button onClick={(e) => removePastResult()}>Retry</button>
      </div>
    );
  }

};

export default ResultsPage;
