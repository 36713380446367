import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ResultsPage from "../pages/ResultsPage";

const CustomerQueryForm = (props) => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  const [customerID, setCustomerID] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(false);

  if (submitStatus) {
    return <ResultsPage timeLogo={props.timeLogo} timeLabel={props.timeLabel} timeProfile={props.timeProfile} timeLogoutButton={props.timeLogoutButton} customerID={customerID}></ResultsPage>;
  } else {
    return (
      <div>
        <form onSubmit={(e) => setSubmitStatus(true)} method="get">
          <input
            type="text"
            id="CID"
            name="CID"
            value={customerID}
            onChange={(e) => setCustomerID(e.target.value)}
          />
          <input type="submit" value="Submit" />
        </form>
      </div>
    );
  }
};

export default CustomerQueryForm;
