import React from "react";
import logo from "../logo.svg";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "../components/LogoutButton";
import Profile from "../components/Profile";
import CustomerQueryForm from "../components/CustomerQueryForm";

const CustomerFormPage = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const timeLogo = Math.random();
  const timeLabel = Math.random();
  const timeProfile = Math.random();
  const timeLogoutButton = Math.random();

  return (
    <div className="App">
      <header className="App-header">
        <img id={timeLogo} src={logo} className="App-logo" alt="logo" />
        <label id={timeLabel} for="CID">
          Welcome Back {user.name} Please Input Your CID:
        </label>
        <Profile timeProfile={timeProfile}></Profile>
        <div>
          <CustomerQueryForm timeLogo={timeLogo} timeLabel={timeLabel} timeProfile={timeProfile} timeLogoutButton={timeLogoutButton}></CustomerQueryForm>
        </div>
        <LogoutButton timeLogoutButton={timeLogoutButton}></LogoutButton>
      </header>
    </div>
  );
};

export default CustomerFormPage;
