import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Profile from "../components/Profile";
import logo from "../logo.svg";

const WelcomePage = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  return (
    <div id="welcomePage">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <label>Welcome Back {user.name}</label>
        <Profile></Profile>
      </header>
    </div>
  );
};

export default WelcomePage;
