import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = (props) => {
  const { logout } = useAuth0();

  return (
    <div id={props.timeLogoutButton}>
      <br />
      <button onClick={() => logout({ returnTo: window.location.origin })}>
        Log Out
    </button>
    </div>
  );
};

export default LogoutButton;