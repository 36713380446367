import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import LoginPage from "./pages/LoginPage";
import AppSyncProvider from "./components/AppSyncProvider";

function App() {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return <LoginPage></LoginPage>;
  } else {
    return <AppSyncProvider></AppSyncProvider>;
  }
}

export default App;
