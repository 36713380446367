import React from "react";
import gql from "graphql-tag";
import { getCustomer } from "../graphql/queries";
import { useQuery } from "@apollo/react-hooks";

const Customer = (props) => {
  const customerId = props.customerID;

  const { loading, error, data } = useQuery(gql(getCustomer), {
    variables: { customerID: customerId },
  });
  if (loading) return <p>Loading ...</p>;
  if (error) return <p>{JSON.stringify(error)}</p>;
  return (
      <div>
          <p>customerID: {data.getCustomer.customerID}</p>
          <p>billingAddress: {JSON.stringify(data.getCustomer.billingAddress)}</p>
          <p>email: {data.getCustomer.email}</p>
          <p>firstName: {data.getCustomer.firstName}</p>
          <p>homePhone: {data.getCustomer.homePhone}</p>
          <p>lastName: {data.getCustomer.lastName}</p>
          <p>phone: {data.getCustomer.phone}</p>
          <p>programCode: {data.getCustomer.programCode}</p>
          <p>salesforceID: {data.getCustomer.salesforceID}</p>
      </div>
  )
};

export default Customer;
