import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloLink } from "apollo-link";
import { createAuthLink } from "aws-appsync-auth-link";
import { createHttpLink } from "apollo-link-http";
import AppSyncConfig from "../aws-exports";
import ApolloClient from "apollo-client";
import CustomerFormPage from "../pages/CustomerFormPage";
import { useAuth0 } from "@auth0/auth0-react";
import { InMemoryCache } from "apollo-cache-inmemory";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import WelcomePage from "../pages/WelcomePage";
import PotatoObservationExplorer from "../pages/PotatoObservationExplorer";

const WithProvider = () => {
  const { getIdTokenClaims } = useAuth0();

  const url = process.env.REACT_APP_API;
  const region = process.env.REACT_APP_REGION;
  // const url = AppSyncConfig.aws_appsync_graphqlEndpoint;
  // const region = AppSyncConfig.aws_appsync_region;
  const auth = {
    type: "OPENID_CONNECT",
    jwtToken: async () => {
      return await getIdTokenClaims().then((t) => {
        console.log(t);
        return t.__raw;
      });
    },
  };

  const link = ApolloLink.from([createAuthLink({ url, region, auth }), createHttpLink({ uri: url })]);
  console.log({ url });
  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
  return (
    <ApolloProvider client={client}>
      <Router>
        <div className="nav">
          <nav>
            <table className="navTable">
              <tr className="nav-col">
                <Link to="/">Home</Link>
              </tr>
              <tr className="nav-col">
                <Link to="/potato-observation-explorer">Potato Observation Explorer</Link>
              </tr>
              <tr className="nav-col">
                <Link to="/customer-form">Customer Form</Link>
              </tr>
            </table>
          </nav>

          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/customer-form">
              <CustomerFormPage />
            </Route>
            <Route path="/potato-observation-explorer">
              <PotatoObservationExplorer />
            </Route>
            <Route path="/">
              <WelcomePage />
            </Route>
          </Switch>
        </div>
      </Router>

      {/* <FormPage /> */}
    </ApolloProvider>
  );
};

export default WithProvider;

// import React, { useState, useContext, useEffect } from "react";
// import { useAuth0 } from "@auth0/auth0-react";
// import AWSAppSyncClient from "aws-appsync";
// import AppSyncConfig from "../aws-exports";
// import { ApolloProvider } from "react-apollo";
// import { Rehydrated } from "aws-appsync-react"; // this needs to also be installed when working with React
// import FormPage from "../pages/FormPage";
// import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";

// export const AppSyncProvider = ({ children }) => {
// const { getIdTokenClaims } = useAuth0();

//   const client = new AWSAppSyncClient({
//     url: AppSyncConfig.aws_appsync_graphqlEndpoint,
//     region: AppSyncConfig.aws_appsync_region,
//     disableOffline: true,
// auth: {
//   type: "OPENID_CONNECT",
//   jwtToken: () => {
//     return getIdTokenClaims().then((t) => t._raw);
//   },
//     },
//   });

//   return (
//     <ApolloProvider client={client}>
//       <ApolloHooksProvider client={client}>
//         {/* <Rehydrated> */}
//         <FormPage></FormPage>
//         {/* </Rehydrated> */}
//       </ApolloHooksProvider>
//     </ApolloProvider>
//   );
// };

// export default AppSyncProvider;
